$font-size: 12px;
$font-size-small: $font-size * 0.9;
$font-size-big: $font-size * 1.5;
$font-size-large: $font-size * 3;
$border-radius: 2px;
$hover-color: lightblue;

$gray-1: #202224;
$gray-2: #2d2d2d;
$gray-3: #555759;
$gray-4: #6e7072;
$gray-5: #848688;
$gray-6: #aaacae;
$gray-7: #c6c8ca;
$gray-8: #dcdee0;
$gray-9: #f0f1f2;
$gray-10: #f8f8f8;
$turq-light: #5dc9e2;
$turq-med: #50b7e0;
$turq-dark: #007d9c;
$turq-dark-with-transparency: #007d9c85;
$abbey: #3f4042;
$blue: #bfeaf4;
$blue-light: #f2fafd;
$black-1: #000;
$black-2: #111111;
$deep-cerulian: #007f9f;
$green: #3a6e11;
$green-light: #5fda64;
$pink: #c21d48;
$pink-light: #fdecf1;
$purple: #542c7d;
$shark: #2b2d2f;
$slate: #253443;
$tundora: #414141;
$white: #fff;
$yellow: #fddd00;
$yellow-light: #fff8cc;
$testimonial: #007f9f;

$header-active-border-thickness: 3px;

$mobile-size: 600px;

* {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
    line-height: 1.4;
}

body {
    margin: 0;
}

header {
    background-color: $turq-dark;
    display: flex;
    border-bottom: 1px solid $white;
    height: 80px;
    align-items: center;

    .logo {
        background-color: $turq-dark-with-transparency;
        padding: 10px;
        height: 40px;
        margin-left: 10px;


        img {
            height: 40px;
        }
    }

    .header-item {
        color: $white;
        padding: 10px;
        font-size: $font-size-big;


        svg {
            fill: $white;
        }

        border-bottom: $header-active-border-thickness solid $turq-dark;
        &:hover {
            border-bottom: $header-active-border-thickness solid $white;
        }

        a:link, a:visited, a:hover, a:active {
            color: $white;
            text-decoration: none;
        }
    }
}

.dropdown {
    @media (max-width: $mobile-size) {
        display: none;
    }

    .title {
        cursor: pointer;

        &:hover {

        }

        a:hover, &:hover, &.visible {
            background-color: $white;
            color: $turq-dark;
            border-bottom: $header-active-border-thickness solid $white;

            a {
                color: inherit;

                svg {
                    fill: $black-2;
                }
            }
        }
    }
    .items {
        display: none;

        &.visible {
            display: flex;
            background-color: $white;
            color: $black-2;
        }

        flex-direction: column;
        position: absolute;
        background-color: white;
        border: 1px solid lightgray;
        border-top: 0px;

        .dropdown-item {
            padding-bottom: 3px;
            margin: 10px;

            width: max-content;

            a:link, a:visited, a:hover, a:active {
                color: $turq-dark;
                text-decoration: none;
            }

            border-bottom: 1px solid $white;
            &:hover {
                border-bottom: 1px solid $turq-dark;
            }
        }
    }
}

.content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;

    @media (max-width: 1200px) {
        margin-left: 50px;
        margin-right: 50px;
    }

    padding-bottom: 40px;

    .card-list {
        display: flex;
        flex-wrap: wrap;

        .card {
            margin-left: 40px;
            margin-bottom: 40px;

            @media (max-width: $mobile-size) {
                margin-left: 0
            }
        }
    }

    .innosol-logo {
        padding-top: 40px;
        width: 100%;
        padding-bottom: 40px;

        @media (max-width: $mobile-size) {
            padding-bottom: 0;
        }


        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 50%;
            max-width: 500px;

            @media (max-width: $mobile-size) {
                margin-left: 40px;
            }
        }
    }

    #messen-regeln-steuern---innovative-solutions {
        strong {
            color: $pink;
        }
    }
}

.reference-item {
    display: flex;
    flex-direction: row;

    .img {
        img {
            max-width: $mobile-size;
        }

        padding-right: 10px;
    }
}

.reference-title {
    strong {
        color: $gray-5;
    }
}

.card {
    border: 1px solid $gray-1;
    max-width: 240px;

    @media (max-width: $mobile-size) {
        max-width: 350px;
    }

    .body {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 180px;

        h1 {
            margin: 0;
            font-size: $font-size-big;
        }

        .card-date {
            margin-top: 5px;
            margin-bottom: 5px;
            color: $gray-4;
            font-size: $font-size;
        }

        .card-content {
            font-size: $font-size-small;
            overflow: hidden;

            display: block;

            @media (max-width: $mobile-size) {
                font-size: $font-size;
            }
        }

        .card-footer {
            a:link, a:visited, a:active {
                color: $pink;
                text-decoration: none;
            }

            a:hover {
                text-decoration: underline;
            }
        }
    }

    .header-img {
        overflow: hidden;
        height: 200px;
        img {
            height: 100%;
            min-width: 100%;
        }
    }
}

.title-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;

    @media (max-width: $mobile-size) {
        margin-left: 0;
    }

    img {
        max-height: 360px;

        @media (max-width: $mobile-size) {
            max-width: calc(600px - 200px);
        }
    }
}

.article {
    h1 {
        margin-bottom: -20px;
    }

    h1, h2 {
        width: max-content;
    }

    td {
        &:first-of-type {
            color: $gray-1;
            padding-right: 20px;
        }
        &:nth-of-type(2) {
            font-weight: bold;
        }
    }
}

.contact-form {
    .control-group {
        .controls {
            label {
                display: inline-block;
                max-width: 100%;
                margin: 10px 0;
                font-weight: 700;
            }
            input {
                width: 100%;
                height: 34px;
                padding: 6px 12px;
                font-size: 14px;
                line-height: 1.42857143;
                color: $gray-1;
                background-color: $white;
                background-image: none;
                border: 1px solid $gray-6;
                border-radius: 4px;
            }

            textarea {
                width: 100%;
                padding: 6px 12px;
                font-size: 14px;
                line-height: 1.42857143;
                color: $gray-1;
                background-color: $white;
                background-image: none;
                border: 1px solid $gray-6;
                border-radius: 4px;
            }
        }
    }

    button {
        color: $white;
        background-color: $turq-dark;
        border-color: $turq-dark;
        display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 4px;
    }
}
